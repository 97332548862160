import { TextField } from "@material-ui/core"
import { FormikProps } from "formik"

import { CreateOrganizationStepWrapper } from "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/organizationCreateWizardStyles"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"

export type TOrganizationNameForm = {
  name: string
}

export const ORG_NAME_FORM_ID = "org-name-form"

export function OrganizationNameStep({
  formData,
}: {
  formData: FormikProps<TOrganizationNameForm>
}) {
  const { t, langKeys } = useTranslate()

  return (
    <CreateOrganizationStepWrapper
      id={ORG_NAME_FORM_ID}
      onSubmit={formData.handleSubmit}
      noValidate
    >
      <MText variant="heading2">
        {t(langKeys.organizations_name_your_organization)}
      </MText>

      <div>
        {t(langKeys.organizations_organization_name_onboarding_details)}
      </div>

      <TextField
        fullWidth
        id="name"
        name="name"
        value={formData.values.name}
        onChange={formData.handleChange}
        onBlur={(e) => {
          formData.setFieldValue("name", e.target.value.trim())
          formData.handleBlur(e)
        }}
        error={formData.touched.name && Boolean(formData.errors.name)}
        helperText={formData.touched.name && formData.errors.name}
        required
        autoFocus
      />
    </CreateOrganizationStepWrapper>
  )
}
